import { createApp, h } from 'vue'
import Oruga from '@oruga-ui/oruga-next'
import { bulmaConfig } from '@oruga-ui/theme-bulma'
import App from './App.vue'
import i18n from './i18n'

// If we are in development mode, load Bulma css and config with window variables
if (process.env.NODE_ENV === "development") {
  require('@oruga-ui/theme-bulma/dist/bulma.css')
  require('./styles/common.css')
  require('./config/config.js')
}

i18n.global.locale.value = window.waxtrapp.locale

const productStatusComponents = document.getElementsByClassName("productstatus-frontend")

for (var i = 0; i < productStatusComponents.length; i++) {
  createApp({
    data () {
      return {
        id: productStatusComponents[i].id
      }
    },
    render: () => h(App)
  })
  .use(i18n)
  .use(Oruga, bulmaConfig)
  .mount('#' + productStatusComponents[i].id)
}
