<template>
  <div v-if="status" :class="['category', category.identifier, category.status]">
    <a :href="category.url" :title="status.label" @click="open">
      <div class="shape" :style="{ borderColor: status.color, color: status.color }">
        <o-icon v-if="status.icon" class="emblem" :pack="iconPack" :icon="status.icon" :style="{ backgroundColor: status.color }"></o-icon>
        <o-icon v-if="category.icon" class="graphic" :pack="category.iconPack || iconPack" :icon="category.icon"></o-icon>
        <div v-if="category.svg" class="graphic" :style="{ fill: status.color }" v-html="category.svg"></div>
      </div>
      <span class="textLabel">{{ category.labelText }}</span>
    </a>
    <o-modal contentClass="modal-content" v-if="category.content" :active="modalActive" :onClose="close">
      <div class="product-status-popin">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ category.labelText }}</p>
          <button class="delete" aria-label="close" @click="close"></button>
        </header>
        <section class="modal-card-body">
          <div class="content" v-html="category.content"></div>
        </section>
      </div>
    </o-modal>
  </div>
</template>

<script>
export default {
  name: 'Category',
  data () {
    return {
      modalActive: false
    }
  },
  props: {
    category: Object,
    status: Object,
    iconPack: String
  },
  methods: {
    open() {
      if (this.category.content) {
        this.modalActive = true
      }
    },
    close() {
      this.modalActive = false
    }
  }
}
</script>
