import axios from 'axios'

var headers = {
    'Content-Type': 'application/json;charset=UTF-8',
    'Accept': 'application/json'
};

axios.defaults.headers = headers;
axios.defaults.data = {};

export default {
    create() {
        return axios.create({
            withCredentials: false
        });
    },
    request(config) {
        const ax = this.create();
        return ax.request(config);
    },
    get(url, config) {
        const ax = this.create();
        return ax.get(url, config);
    },
    delete(url, config) {
        const ax = this.create();
        return ax.delete(url, config);
    },
    post(url, data, config) {
        const ax = this.create();
        return ax.post(url, data, config);
    },
    put(url, data, config) {
        const ax = this.create();
        return ax.put(url, data, config);
    },
    patch(url, data, config) {
        const ax = this.create();
        return ax.patch(url, data, config);
    }
}