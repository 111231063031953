<template>
  <ProductStatus/>
</template>

<script>
import ProductStatus from './components/ProductStatus.vue'

export default {
  name: 'productStatusFrontend',
  components: {
    ProductStatus
  }
}
</script>
