<template>
  <div class="bulma">
    <h2 v-if="title" class="title">{{ title }}</h2>
    <o-notification v-if="error" type="danger" variant="danger" :closable="false" has-icon>
      <b>{{ error }}</b>
      <p>{{ message }}</p>
    </o-notification>
    <p v-if="noData" class="empty text-base">{{ $t('NO_DATA') }}</p>
    <div :class="['categories', style]">
      <o-loading :active="loading" :full-page="false"></o-loading>
      <Category v-for="category in categories" :category="category" :status="statusses[category.status]" :iconPack="iconPack" :key="category.id"/>
    </div>
  </div>
</template>

<script>
import Category from './Category.vue'
import axios from '../services/axios.js'

export default {
  name: 'ProductStatus',
  data () {
    return {
      title: null,
      error: null,
      message: null,
      accessToken: null,
      loading: false,
      noData: false,
      style: window.waxtrapp[this.$root.id].style,
      iconPack: window.waxtrapp[this.$root.id].iconPack || 'fas',
      statusses: window.waxtrapp[this.$root.id].statusses || [],
      categories: []
    }
  },
  components: {
    Category
  },
  methods: {
    getAccessToken() {
      let config
      if (window.vue && window.vue.auth) {
        config = {
          headers: {
            Authorization: window.vue.auth
          }
        }
      }
      axios.post(window.waxtrapp[this.$root.id].accessTokenUrl + '/token', null, config)
      .then(response => {
        this.accessToken = response.data.access_token
        this.loadData()
      })
      .catch(error => {
        this.loading = false
        if (error.response !== undefined) {
          if (error.response.status == 401) {
            window.location.href = window.waxtrapp[this.$root.id].loginUrl
          }
          else {
            this.error = error.response.data.error
          }
        }
        else {
          this.throwError(error)
        }
      })
      .finally()
    },
    loadData() {
      let headers = {}
      if (this.accessToken !== null) {
        headers.Authorization = 'Bearer ' + this.accessToken
      }
      axios.get(window.waxtrapp[this.$root.id].productStatusUrl, {
        headers: headers,
        params: window.waxtrapp[this.$root.id].params
      })
      .then(response => {
        if (response.data.data == null || response.data.data[0] == null) {
          this.noData = true
        } else {
          this.categories = response.data.data
        }
      })
      .catch(error => {
        if (error.response !== undefined) {
          this.error = error.response.data.error
          this.message = error.response.data.message
        }
        else {
          this.throwError(error)
        }
      })
      .finally(this.loading = false)
    },
    throwError(error) {
      this.loading = false
      this.error = error.name
      this.message = error.message
    }
  },
  mounted () {
    if (window.waxtrapp[this.$root.id] !== undefined) {
      this.title = window.waxtrapp[this.$root.id].title
      this.loading = true
      if (window.waxtrapp[this.$root.id].accessTokenUrl !== undefined) {
        this.getAccessToken()
      } else {
        this.loadData()
      }
    }
  }
}
</script>

<style>
.productstatus-frontend .empty {
  text-align: center;
}

.productstatus-frontend .categories {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 0.5em 0;
  min-height: 100px;
  position: relative;
}

.productstatus-frontend .categories .category {
  margin: 1em 0;
  min-width: 120px;
  max-width: 120px;
}
.productstatus-frontend .categories .category:hover {
  text-decoration: none !important;
}

.productstatus-frontend .categories .category .shape {
  border: 2px solid black;
  margin: 0 auto;
  position: relative;
  height: 90px;
  width: 90px;
}
.productstatus-frontend .categories.circle .category .shape {
  border-radius: 100%;
}

.productstatus-frontend .categories .category .shape .emblem {
  background: black;
  color: white;
  position: absolute;
  height: 23px;
  width: 23px;
  top: -2px;
  right: -2px;
  text-align: center;
}
.productstatus-frontend .categories.circle .category .shape .emblem {
  border-radius: 100%;
}

.productstatus-frontend .categories .category .shape .emblem .mdi::before
{
  font-size: 18px;
}

.productstatus-frontend .categories .category .shape .emblem .fas::before
{
  font-size: 14px;
}

.productstatus-frontend .categories .category .shape .graphic {
  fill: black;
  height: 100%;
  width: 100%;
  text-align: center;
}

.productstatus-frontend .categories .category .shape .graphic svg {
  position: absolute;
  margin: -28% 0 0 -28%;
  top: 50%;
  left: 50%;
  height: 56%;
  width: 56%;
}

.productstatus-frontend .categories .category .shape .graphic .mdi::before
{
  font-size: 56px;
}

.productstatus-frontend .categories .category .shape .graphic .fas::before
{
  font-size: 42px;
}

.productstatus-frontend .categories .category .textLabel {
  display: block;
  font-size: 0.9em;
  margin-top: 0.6em;
  text-align: center;
}
</style>
